<template>
  <div>
    <div class="block banner bg">
      <div class="container">
        <div class="box">
          <h1>{{$t("staticAcceleration")}}</h1>
          <h2>{{$t('solution122')}}</h2>
          <p class="sm">{{$t('solution123')}}</p>
          <el-button type="primary">{{$t("contactUs")}}</el-button>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>
          {{$t('solution73')}}
        </h2>
        <ul class="list1 three">
          <li class="icon58">
            <h5>{{$t('solution124')}}</h5>
            <p>{{$t('solution125')}}</p>
          </li>
          <li class="icon25">
            <h5>{{$t('solution74')}}</h5>
            <p>{{$t('solution126')}}</p>
          </li>
          <li class="icon28">
            <h5>{{$t('product134')}}</h5>
            <p>{{$t('solution127')}}</p>
          </li>
          <li class="icon29">
            <h5>{{$t('solution82')}}</h5>
            <p>{{$t('solution128')}}</p>
          </li>
          <li class="icon57">
            <h5>{{$t('solution129')}}</h5>
            <p>{{$t('solution130')}}</p>
          </li>
          <li class="icon27">
            <h5>{{$t('solution78')}}</h5>
            <p>{{$t('solution131')}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image4.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution86')}}</h4>
              <p>{{$t('solution132')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image26.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution133')}}</h4>
              <p>{{$t('solution134')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image7.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution135')}}</h4>
              <p>{{$t('solution136')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image27.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution137')}}</h4>
              <p>{{$t('solution138')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image13.svg" /></div>
            <div class="cont">
              <h4>{{$t('solution99')}}</h4>
              <p>{{$t('solution139')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>{{$t('solution140')}}</h2>
        <p>{{$t('solution141')}}</p>
        <div class="ac" style="margin-bottom: 1.3rem"><img src="@/assets/images/image29.svg" /></div>
        <PlanBox />
      </div>
    </div>
  </div>
</template>
<script>
  import PlanBox from '@/components/PlanBox.vue'

export default {
  name: "SolutionStatic",
  components: {
    PlanBox
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('staticAcceleration')+'-'+this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }
</style>
